import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import StepWizard from "react-step-wizard";

import { ModalDialogStyled } from "./ReportModal.styled";
import { Navigation } from "./Navigation";
import { Submitting } from "./Submitting";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { useEvents, useReport } from "../../hooks";

const defaultStepData = { bet: undefined, comment: "", error: undefined, files: [] };

export const ReportModal = React.memo(function ReportModal({ close, onSubmitted, show }) {
  const [submittingStatus, setSubmittingStatus] = useState(undefined);
  const [stepData, setStepData] = useState(defaultStepData);
  const { sendReport } = useEvents();
  const { reportData } = useReport();

  const closeAndReset = () => {
    close();
    setSubmittingStatus(undefined);
    setStepData(defaultStepData);
  };

  const reportType = reportData?.type;

  const getReportData = () => {
    let generalReportData = {
      comment: stepData.comment,
      error: stepData.error,
      startedOn: stepData.startedOn || new Date(),
      files: stepData.files.map((file) => file.file),
    }

    if (reportType === 'arb'){
      generalReportData.bookie = stepData.bet.bookie;
      generalReportData.arb = reportData;
    }

    console.log(reportData);
    console.log(generalReportData);
    if (reportType === 'valuebet'){
      generalReportData.bookie = reportData.bet.bookie;
      generalReportData.valuebet = reportData;
    }

    console.log(generalReportData);

    return generalReportData;
  }

  const onSubmit = async () => {
    setSubmittingStatus({ status: 'running' });
    try {
      const report = {
        type: reportType,
        data: getReportData()
      };
      await sendReport(report);
      setSubmittingStatus({ status: 'success' });
      if (onSubmitted) onSubmitted();
    } catch (error) {
      setSubmittingStatus({ status: 'error', error });
    }
  };

  return (
    <Modal
      show={show}
      onHide={closeAndReset}
      backdrop={submittingStatus ? "static" : true}
      centered
      dialogAs={ModalDialogStyled}
      className="report-modal"
    >
      <Modal.Header closeButton={!submittingStatus || (submittingStatus && submittingStatus !== 'running')}>
        <Modal.Title>Reportar</Modal.Title>
      </Modal.Header>
      {submittingStatus && <Submitting status={submittingStatus} />}
      <StepWizard nav={<Navigation type={ reportType } />} className={submittingStatus && "d-none"}>
        { reportType === 'arb' && <Step1 data={stepData} setData={setStepData} /> }
        <Step2 data={stepData} setData={setStepData} type={ reportType }/>
        <Step3 data={stepData} setData={setStepData} onSubmit={onSubmit} />
      </StepWizard>
    </Modal>
  );
});
