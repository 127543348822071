import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { CustomButton } from "./ReportModal.styled";

const ArbReportErrors = [
  { error: "changed_odds", text: "Cuota diferente", filesRequired: 1 },
  { error: "handicap_changed", text: "Hándicap diferente", filesRequired: 1 },
  { error: "market_closed", text: "Mercado cerrado", filesRequired: 1 },
  { error: "market_unavailable", text: "Mercado inexistente" },
  { error: "event_information_outdated", text: "Estado evento distinto", filesRequired: 1 },
  { error: "event_closed", text: "Evento cerrado", filesRequired: 1 },
  { error: "event_unavailable", text: "Evento inexistente" },
  { error: "event_mismatched", text: "Evento diferente" },
  { error: "other", text: "Otro" },
];

const GeneralReportErrors = [
  { error: "arb_not_found", text: "Arbitraje no encontrado", filesRequired: 2 },
];

const ValuebetReportErrors = [
  { error: "changed_odds", text: "Cuota diferente", filesRequired: 1 },
  { error: "handicap_changed", text: "Hándicap diferente", filesRequired: 1 },
  { error: "market_closed", text: "Mercado cerrado", filesRequired: 1 },
];

const getErrors = (type) => {
  if(type === 'arb') return ArbReportErrors;
  if(type === 'valuebet') return ValuebetReportErrors;
  return GeneralReportErrors;
}

export const Step2 = ({ data, nextStep, previousStep, setData, type }) => {
  const { error: selectedError } = data;

  const selectError = (error) => {
    setData(data => ({ ...data, error }));
    nextStep();
  };

  return (
    <>
      <Modal.Body>
        <p className="lead text-center" style={{ fontWeight: "bold" }}>¿Cuál es el error?</p>
        <div className="d-flex justify-content-center">
          <div className="d-inline-block">
            {getErrors(type).map((error) => (
              <CustomButton
                variant={selectedError?.error === error.error ? "info" : "secondary"}
                block
                className="w-100"
                onClick={() => selectError(error)}
                style={{ fontWeight: "bold" }}
              >
                {error.text}
              </CustomButton>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button variant="secondary" onClick={previousStep}>
          Anterior
        </Button>
      </Modal.Footer>
    </>
  );
};
